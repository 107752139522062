





















































import { Component, Prop, Vue } from "vue-property-decorator";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { LangCodeDescription } from "@/types/language";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";

/**
 * AdminLangCodeDescriptionTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
    },
})
export default class AdminLangCodeDescriptionTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;
    @Prop({ type: Array, required: true })
    readonly langCodeDescriptions!: LangCodeDescription[];

    private availableLanguages: string[] = [];

    private defaultLangCodeDescriptionItem: LangCodeDescription = {
        id: -1,
        langcode: this.$root.$i18n.locale,
        title: "",
        description: "",
        keywords: [],
    };

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            this.availableLanguages = await this.fetchLanguages();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Fetches all languages
     *
     * @returns Promise<string[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchLanguages(): Promise<string[]> {
        const response = (await axios.get<APIResponse<string[]>>("/languages"))
            .data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$t("language.title").toString(),
                value: "title",
            },
            {
                text: this.$tc("language.description").toString(),
                value: "description",
            },
            {
                text: this.$t("language.langCode").toString(),
                value: "langcode",
            },
        ];
    }
}
