












































import { Component, Prop, Vue } from "vue-property-decorator";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { ArticlePrice, ArticlePriceClass } from "@/types/shop/article";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";

/**
 * AdminShopArticlePriceClassTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
    },
})
export default class AdminShopArticlePriceClassTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;
    @Prop({ type: Array, required: true })
    readonly articlePrices!: ArticlePrice[];

    private articlePriceClasses: ArticlePriceClass[] = [];

    private defaultArticlePriceItem: ArticlePrice = {
        id: -1,
        priceClassId: -1,
        minOrderCount: 1,
        bonusPoints: 0,
        price: 1,
        currency: "",
        currencyPosition: 2,
        tax: 19,
    };

    private selectedArticlePriceClass: ArticlePriceClass = {
        id: -1,
        name: "",
        currency: "",
        currencyPosition: 2,
        tax: 19,
    };

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$tc("shop.article.price").toString(),
                value: "price",
            },
            {
                text: this.$t("shop.article.priceClass.currency").toString(),
                value: "currency",
            },
            {
                text: this.$t(
                    "shop.article.priceClass.currency.position"
                ).toString(),
                value: "currencyPosition",
            },
        ];
    }

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            this.articlePriceClasses = await this.fetchArticlePriceClasses();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Function will be called when crud dialog was opened
     * Sets selectedPriceClass
     *
     * @param dialogMode dialog mode
     * @param crudItem current crud item
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private crudDialogOpened(dialogMode: string, crudItem: ArticlePrice) {
        if (dialogMode === "create") return;

        const articlePriceClass = this.articlePriceClasses.find(
            (priceClass) => priceClass.id === crudItem.priceClassId
        );
        if (!articlePriceClass) return;

        this.selectedArticlePriceClass = articlePriceClass;
    }

    /**
     * Function will be called when crud dialog was saved
     * modifies data and emit crudDialogSave
     *
     * @param updatedItems updated items
     * @param dialogMode dialog mode
     * @param crudItem current crud item
     * @param crudItemIndex index of current crud item
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private crudDialogSaved(
        updatedItems: ArticlePrice[],
        crudDialogMode: string,
        crudItem: ArticlePrice,
        crudItemIndex: number
    ) {
        if (crudDialogMode !== "delete") {
            updatedItems[crudItemIndex].priceClassId =
                this.selectedArticlePriceClass.id;
            updatedItems[crudItemIndex].currency =
                this.selectedArticlePriceClass.currency;
            updatedItems[crudItemIndex].currencyPosition =
                this.selectedArticlePriceClass.currencyPosition;
            updatedItems[crudItemIndex].tax =
                this.selectedArticlePriceClass.tax;
        }

        this.$emit(
            "crudDialogSave",
            updatedItems,
            crudDialogMode,
            crudItem,
            crudItemIndex
        );
    }

    /**
     * Fetches all article price classes
     *
     * @returns Promise<ArticlePriceClass[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchArticlePriceClasses(): Promise<ArticlePriceClass[]> {
        const response = (
            await axios.get<APIResponse<ArticlePriceClass[]>>(
                "/articles/priceclasses"
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
