var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AdminBaseItemTable',{attrs:{"title":_vm.$tc('language.description', 100),"items":_vm.langCodeDescriptions.map(function (item, key) { return (Object.assign({}, item, {key: key})); }),"item-key":"key","headers":_vm.tableHeaders,"editable":_vm.editable,"defaultCRUDItem":_vm.defaultLangCodeDescriptionItem},on:{"crudDialogSave":function (updatedItems, crudDialogMode, crudItem, crudItemIndex) { return _vm.$emit(
                'crudDialogSave',
                updatedItems,
                crudDialogMode,
                crudItem,
                crudItemIndex
            ); }},scopedSlots:_vm._u([{key:"crud-form",fn:function(ref){
            var item = ref.item;
return [_c('v-select',{attrs:{"label":_vm.$t('language.langCode'),"items":_vm.availableLanguages,"rules":[function (v) { return !!v; }],"required":""},model:{value:(item.langcode),callback:function ($$v) {_vm.$set(item, "langcode", $$v)},expression:"item.langcode"}}),_c('v-text-field',{attrs:{"label":_vm.$t('language.title'),"rules":[function (v) { return !!v; }],"required":""},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}}),_c('v-text-field',{attrs:{"label":_vm.$tc('language.description'),"rules":[function (v) { return !!v; }],"required":""},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}),_c('v-combobox',{attrs:{"label":_vm.$tc('language.keyword', 100),"multiple":"","rules":[function (v) { return !!v; }],"required":""},model:{value:(item.keywords),callback:function ($$v) {_vm.$set(item, "keywords", $$v)},expression:"item.keywords"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }